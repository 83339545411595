import React from "react";
import logo from "./assets/statnett-logo.svg";

function App() {
  return (
    <div>
      <header>
        <img src={logo} alt="Statnett Logo" style={{ height: "50px" }} />
      </header>
      <p>
        Funksjonaliteten fra gamle pq.statnett.no inngår nå fra desember 2024 i
        NASDAT og PQ Portal. Den som er fosweb ansvarlig i selskapet ditt kan gi
        deg som har tjenstlig behov for tilgang til disse måledataene rollen PQ
        portal i fosweb.
      </p>
      <p>
        <strong>PQ Portal</strong> = Visning, analyse og statistikk av måledata
        i NASDAT
      </p>
      <p>
        <strong>NASDAT</strong> = Nasjonal spenningskvalitet database
      </p>
    </div>
  );
}

export default App;
